var nirRegex = /([12378])( ?)([0-9]{2})( ?)(0[0-9]|1[012]|[23][0-9]|4[12]|[5-9][0-9])( ?)(((2[AB]|0[1-9]|[1-9][0-9])( ?)(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990))|((9[78][0-9])( ?)([0-9]{2})))( ?)(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})( ?)(0[1-9]|[1-8][0-9]|9[0-7])/

// assuranceCode must be a string
function nirValidator(assuranceCode){       
    if(nirRegex.test(assuranceCode)){
    	assuranceCode = assuranceCode.replace(/ /g,'')
        var nir = Number(
            assuranceCode
            .replace("2A","19")
            .replace("2B","18")
            .slice(0, assuranceCode.length - 2)
        );
        return ( 97 - nir % 97 ) == Number( assuranceCode.slice(-2) );
    } else {
        return false;
    }
}